import React from "react";
import { Link, Outlet, useRouteLoaderData } from 'react-router-dom';
import { accountPath } from "../models/Paths";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding } from '@fortawesome/free-solid-svg-icons'
import { Container } from "../viewer/components/styled/Container";
import { useLocation } from 'react-router-dom';

const Account = () => {
  const account = useRouteLoaderData('account');
  let location = useLocation();

  const activeClasses = "inline-block p-4 text-primary-600 border-b-2 border-primary-600 rounded-t-lg active dark:text-primary-500 dark:border-primary-500"
  const inactiveClasses = "inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300"

  const defaultStageActive = location.pathname.endsWith(account.slug)
  const stagesActive = location.pathname.endsWith("stages")
  const membershipsActive = location.pathname.endsWith("memberships")
  const subscriptionActive = location.pathname.endsWith("subscription")
  const settingsActive = location.pathname.endsWith("settings")

  return (
    <Container>
      <div className="grow flex h-screen flex-col">
        <div className="flex flex-row text-2xl items-center"><FontAwesomeIcon icon={faBuilding} />&nbsp;{account.name}</div>
        <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200">
          <ul className="flex flex-wrap -mb-px">
            <li className="me-2">
              <Link className={defaultStageActive ? activeClasses : inactiveClasses} to={accountPath(account.slug)}>Projects</Link>
            </li>
            <li className="me-2">
              <Link className={stagesActive ? activeClasses : inactiveClasses} to={accountPath(account.slug) + "/stages"}>Stages</Link>
            </li>
            <li className="me-2">
              <Link className={membershipsActive ? activeClasses : inactiveClasses} to={accountPath(account.slug) + "/memberships"}>Memberships</Link>
            </li>
            <li className="me-2">
              <Link className={subscriptionActive ? activeClasses : inactiveClasses} to={accountPath(account.slug) + "/subscription"}>Subscription</Link>
            </li>
            <li className="me-2">
              <Link className={settingsActive ? activeClasses : inactiveClasses} to={accountPath(account.slug) + "/settings"}>Settings</Link>
            </li>
          </ul>
        </div>
        <Outlet />
      </div>
    </Container>
  );
}

export default Account;
